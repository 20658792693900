



























































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { IRecruitParams, IScenarioData } from '@/interfaces';
import { IQuestionCreate, QuestionTypes, IQuestionData } from '@/interfaces';
import QuestionsList from '@/components/QuestionsList.vue';
import { Validator } from 'vee-validate';
import AddBinaryQuestion from '@/components/AddBinaryQuestion.vue';
import AddScenarioQuestion from '@/components/AddScenarioQuestion.vue';
import AddOpenQuestion from '@/components/AddOpenEndedQuestion.vue';
import AddNominalQuestion from '@/components/AddNominalQuestion.vue';
import { readToken } from '@/store/main/getters';
import { api } from '@/api';
import { commitAddNotification } from '@/store/main/mutations';
import SocialMediaShareButtons from '@/components/SocialMediaShare.vue';
import RecruitExternal from '@/components/RecruitExternalNew.vue';
import store from '@/store';
import { dispatchGetUserProfile } from '@/store/main/actions';
import { dispatchRecruitExternal, dispatchGetSessionData, dispatchDeleteQuestion, dispatchUpdateQuestion, dispatchUpdateContract, dispatchCreateQuestions } from '@/store/sessionData/actions';
import ChatPreview from '@/components/ChatPreview.vue';
import {
  dispatchDeleteSession,
  dispatchUpdateSession,
} from '@/store/sessions/actions';
import { readSessionData } from '@/store/sessionData/getters';
import { publicSessionLinkBase } from '@/env';
import draggable from 'vuedraggable';
import { dispatchOrderQuestions } from '@/store/sessionData/actions';

Validator.extend('required', {
  getMessage: (field) => 'This field is required',
  validate: (value) => !!value,
});

@Component({
  components: {
    'question-list': QuestionsList,
    'add-binary-question': AddBinaryQuestion,
    'add-scenario-question': AddScenarioQuestion,
    'add-open-question': AddOpenQuestion,
    'add-nominal-question': AddNominalQuestion,
    'social-media-share-buttons': SocialMediaShareButtons,
    'recruit-external': RecruitExternal,
    'chat-preview': ChatPreview,
    'draggable': draggable,
  },
})
export default class CreateStudy extends Vue {
  public valid = false;
  public firstTitle = 'Ex: US public’s understanding and perceptions of AI';
  public title = '';
  public description = '';
  public redirectUrl = '';
  public questions: IQuestionData[] = [];
  public listReload = 0;
  public studyStep = 0;
  public studyID: string | null = null;

  public async handleOrderChange(event) {
    if (!this.studyID) {
      return;
    }
    await dispatchOrderQuestions(
      store,
      { sessionId: this.studyID, questions: [...this.questions] }
    );
    this.listReload++;
  }

  public resetSession() {
    this.title = '';
    this.description = '';
    this.redirectUrl = '';
    this.questions = [];
    this.$validator.reset();
  }
  public async addQuestion(question) {
    if (!this.studyID) {
      return;
    }
    await dispatchCreateQuestions(this.$store, {
      sessionId: this.studyID,
      questions: [question],
    });
    this.questions = this.sessionData.questions_with_data;
  }

  public async removeQuestion(index) {
    await dispatchDeleteQuestion(this.$store, {
      sessionId: this.$route.params.id,
      questionId: this.sessionData.questions_with_data[index].id,
    });
    this.questions.splice(index, 1);
    this.listReload++;
  }

  public async updateQuestion(question, index) {
    this.updateContracts(index, question.contracts);
    await dispatchUpdateQuestion(this.$store, {
      sessionId: this.$route.params.id,
      questionId: this.sessionData.questions_with_data[index].id,
      question: {
        title: question.title,
        context: question.context,
        show_estimate_after: question.show_estimate_after,
        show_estimate: question.show_estimate,
      }
    });
    this.listReload++;
  }

  public async submit() {
    await this.$router.push(`/main/user/view/${this.studyID}`);
  }

  public addQuestionDialog = false;
  public carousel = 0;
  public selectedType: QuestionTypes = QuestionTypes.binary;

  public types = [
    {
      text: 'Binary question',
      value: QuestionTypes.binary,
      bool: false
    },
    {
      text: 'Scenario question',
      value: QuestionTypes.scenario,
      bool: false
    },
    {
      text: 'Open question',
      value: QuestionTypes.open,
      bool: false
    },
  ];

  public resetCarousel() {
    this.carousel = 0;
  }

  public toggleQuestion(index: number) {
    if (index == 0) {
      this.selectedType = QuestionTypes.binary;
    } else if (index == 1) {
      this.selectedType = QuestionTypes.scenario;
    } else if (index == 2) {
      this.selectedType = QuestionTypes.open;
    } else if (index == 3) {
      this.selectedType = QuestionTypes.nominal;
    }
    this.moveCarousel(true);
  }

  public moveCarousel(value = true) {
    if (this.carousel >= 0) {
      if (value) {
        this.carousel++;
      } else { this.carousel--; }
    }
  }

  public toggleQuestionDialogue(value: boolean) {
    this.addQuestionDialog = value;
    this.resetCarousel();
  }

  beforeDestroy() { this.$validator.pause(); }

  get buttonSize() {
    return this.$vuetify.breakpoint.name === 'xs' ? { ['small']: true } : {};
  }

  get mobile() {
    return this.$vuetify.breakpoint.name == 'xs';
  }

  public studyLoading = false;
  public questionLoading = false;
  public nextButtonText = 'Analyse';
  public generateQuestionButtonText = 'Generate Question';

  public async generateStudy() {
    if (this.title.length < 10) {
      commitAddNotification(this.$store, { content: 'Topic must be at least 10 characters long', color: 'error' });
      return;
    }
    this.nextButtonText = 'Generating...';
    const token = readToken(this.$store);
    this.studyLoading = true;
    try {
      const response = await api.generateStudy(token, this.title);
      const generatedStudy = response.data;
      this.firstTitle = this.title;
      this.title = generatedStudy.title;
      this.questions = [];
      this.studyID = generatedStudy.id;
      if (!this.studyID) {
        commitAddNotification(this.$store, { content: 'Failed to generate study', color: 'error' });
        this.nextButtonText = 'Analyse';
        this.studyLoading = false;
        return;
      }
      await dispatchGetSessionData(store, { id: this.studyID });
      this.questions = this.sessionData.questions_with_data;
    } catch (error) {
      if (error.response) {
        commitAddNotification(this.$store, { content: `${error.response.data.detail}`, color: 'error' });
      } else {
        commitAddNotification(this.$store, { content: 'Failed to generate study', color: 'error' });
      }
      
      this.nextButtonText = 'Regenerate';
      this.studyLoading = false;
    }
    this.nextButtonText = 'Regenerate';
    this.studyLoading = false;
  }

  public async generateSingleQuestion() {
    this.generateQuestionButtonText = 'Generating...';
    const token = readToken(this.$store);
    this.questionLoading = true;
    try {
      const response = await api.generateSingleQuestion(token, { title: this.title, questions: this.questions });
      const generatedQuestion = JSON.parse(response.data);
      this.addQuestion({
        'title': generatedQuestion.title,
        'context': '',
        'type': generatedQuestion.type,
        'contracts': generatedQuestion.contracts,
        'randomize_qs': false
      });
    }
    catch (error) {
      commitAddNotification(this.$store, { content: 'Failed to generate question', color: 'error' });
    }
    this.generateQuestionButtonText = 'Generate Question';
    this.questionLoading = false;
    this.toggleQuestionDialogue(false);
  }

  public async updateContracts(questionIndex, newContracts) {
    if (!this.sessionData.questions_with_data) {
      return;
    }
    const question = this.sessionData.questions_with_data[questionIndex];
    const questionData = this.sessionData.questions_with_data[questionIndex].data;
    const questionContracts = (questionData as IScenarioData[]).map((data) => data.contract);

    if (question.type == 'scenario' && newContracts.length < 3) {
      commitAddNotification(this.$store, { content: 'A scenario question must have at least 3 scenarios', color: 'error' });
      return false;
    }
    else if (question.type == 'nominal' && newContracts.length < 2) {
      commitAddNotification(this.$store, { content: 'A nominal question must have at least 2 scenarios', color: 'error' });
      return false;
    }
    if (newContracts.length == questionContracts.length) {
      questionContracts.forEach((contract, i) => {
        questionContracts[i].text = newContracts[i];
      });
    }
    else if (newContracts.length > questionContracts.length) {
      newContracts.forEach((contract, i) => {
        if (i < questionContracts.length) {
          questionContracts[i].text = contract;
        }
        else {
          questionContracts.push({ text: contract, question_id: question.id, language_id: questionContracts[0].language_id });
        }
      });
    }
    else {
      questionContracts.forEach((contract, i) => {
        if (i < newContracts.length) {
          questionContracts[i].text = newContracts[i];
        }
        else {
          questionContracts[i].text = '';
        }
      });
    }
    await dispatchUpdateContract(this.$store, questionContracts);
    await dispatchGetSessionData(store, { id: this.sessionData.id });
    this.questions = this.sessionData.questions_with_data;
    return true;
  }

  public async removeContract(question, index, questionIndex) {
    const ok = await this.updateContracts(questionIndex, question.contracts.filter((_, i) => i != index));
    if (ok) {
      question.contracts.splice(index, 1);
      this.listReload++;
    }
  }

  public backHome() {
    this.$router.push({
      name: 'main-session-sessions'
    });
  }

  get showHomeButton() {
    return this.$route.query.welcome != 'yes';
  }

  public actionsComponent(index) {
    return this.$refs['question-list-' + index]![0];
  }

  public editQuestion(index) {
    const component = this.actionsComponent(index);
    component.openDialog();
  }

  public addOption(index, question) {
    this.editQuestion(index);
    setTimeout(() => {
      const component = this.actionsComponent(index);
      component.addScenario();
    }, 100);
  }

  get studyLink() {
    return `${publicSessionLinkBase}${this.studyID}`;
  }
  public slackDialog = false;
  public slackMsg = `Join my Cardinal study!\n${this.studyLink}`;
  public agentCount = 5;

  @Watch('slackDialog')
  onSlackDialogChange(val: boolean, oldVal: boolean) {
    if (oldVal == true) {
      this.slackMsg = `Join my Cardinal study!\n${this.studyLink}`;
    }
    else {
      this.slackDialog = val;
    }
  }

  public toClipboard(str) {
    const el = document.createElement('textarea');
    el.addEventListener('focusin', e => e.stopPropagation());
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  public async recruitLucid(params: IRecruitParams) {
    await dispatchRecruitExternal(
      this.$store,
      { sessionId: this.$route.params.id, params: params }
    );
    await dispatchGetUserProfile(this.$store);
    await dispatchGetSessionData(store, { id: this.$route.params.id });
  }


  public deleteStudyDialog = false;
  public launchDialog = false;
  public aiLaunchDialog = false;
  public async deleteSession() {
    if (!this.studyID) {
      return;
    }
    await dispatchDeleteSession(this.$store, this.studyID);
    await this.$router.push('/main/user/session/all');
  }

  public async agreeToLaunch() {
    if (!this.studyID) {
      return;
    }
    await dispatchUpdateSession(this.$store, {
      id: this.studyID,
      session: {
        'state': 'active'
      },
    });
    this.launchDialog = false;
    this.submit();
  }

  get sessionData() {
    return readSessionData(this.$store);
  }

  public async recruitAI() {
    if (!this.studyID) {
      return;
    }
    try {
      const token = readToken(this.$store);
      await dispatchUpdateSession(this.$store, {
        id: this.studyID,
        session: {
          'state': 'active'
        },
      });
      await dispatchGetSessionData(store, { id: this.studyID });
      if (this.sessionData.state !== 'active') {
        commitAddNotification(this.$store, { content: 'Failed to launch study', color: 'error' });
        this.aiLaunchDialog = false;
        return;
      }
      const response = await api.recruitAI(token, this.studyID, this.agentCount);
      if (response.status === 200) {
        commitAddNotification(this.$store, { content: 'AI agents recruited successfully and running!', color: 'success' });
        await this.$router.push(`/main/user/view/${this.studyID}`);
      }
    } catch (error) {
      commitAddNotification(this.$store, { content: 'Failed to recruit AI agents', color: 'error' });
    }
    this.aiLaunchDialog = false;
  }
}
